


















































import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { blockchainHandler } from "@/blockchain/index";
import { Classes, MonsterTypes, CustomRaces } from "@/constants";

@Observer
@Component({
  components: {},
})
export default class SelectLockPeriod extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;

  monsterTypes = MonsterTypes;
  classes = Classes;
  races = CustomRaces;

  id = "";
  type = "";
  monsterClass = "";
  race = "";

  search() {
    this.vm.searchMyMonsters({
      id: this.id,
      type: this.type,
      monsterClass: this.monsterClass,
      race: this.race,
      page: 1,
    });
  }
}
